export default {
  document: {
    documents: {
      title: 'Liste des documents',
      table: {
        title: 'Liste des documents',
        columns: {
          fileName: 'Nom du document',
          extension: 'Extension',
          type: 'Type de document',
          creationDate: 'Date de création'
        },
        detail: 'Détail du client associé',
        download: 'Télécharger'
      },
      tagChoice: {
        placeholder: 'Selectionnez un type',
        choiceLibelle: 'Type de document'
      },
      tags: {
        RECEIPT: 'Facture',
        QUOTATION: 'Devis',
        AUDIT: 'Audit',
        DPE: 'DPE',
        REPORT: 'Audit',
        EVENT: "Avis d'imposition",
        OTHER: 'Autre',
        TAX_NOTICE: "Avis d'imposition",
        WORK_CERTIFICATE: 'Attestation de travaux',
        ECOPTZ_BORROWER_FORM: 'Formulaire Emprunteur ECOPTZ',
        ECOPTZ_COMPANY_FORM: 'Formulaire Entreprise ECOPTZ',
        ANAH_ADVANCE_FORM: 'Formulaire avances ANAH',
        ANAH_ADVANCE_PAYMENT_FORM: 'Formulaire acomptes ANAH',
        MINUTES_OF_RECEIPT: 'Procès verbal de réception',
        CEE_VALUATION_CERTIFICATE: 'Attestation de valorisation CEE',
        ENERGY_STUDY: 'Etude énergétique',
        WORK_BILL: 'Facture accompagnement',
        ACCOMPANYING_BILL: 'Contrat accompagnement',
        ACCOMPANYING_CONTRACT: 'Facture travaux'
      }
    },
    proQuotationEdit: {
      title: 'Détails du devis',
      form: {
        fields: {
          professional: 'Professionnel',
          reference: 'Référence',
          date: 'Date du devis',
          expiryDuration: 'Durée de validité (jours)',
          amountTTC: 'Montant TTC',
          amountHT: 'Montant HT',
          advanceTTC: 'Acompte TTC',
          state: 'État',
          workTypes: 'Types de travaux',
          states: {
            DELETED: 'Supprimé',
            DEPOSIT: 'Déposé',
            SIGNED: 'Signé'
          }
        }
      }
    }
  }
}
