import PublicLayout from '@/common/layouts/PublicLayout.vue'
import PrivateLayout from '@/common/layouts/PrivateLayout.vue'

export const enum Layout {
  Public,
  Private
}

export const layouts: Record<Layout, unknown> = {
  [Layout.Public]: PublicLayout,
  [Layout.Private]: PrivateLayout
}
