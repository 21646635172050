import { http } from '@/common/api'
import { refreshToken } from '@/common/services/usersService'
import router from '@/router'
import type { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { useTrackDataAPIStore } from '@/common/stores/trackDataApi'
import { useAuthData } from '@/common/stores/authData'

export default function interceptor(): void {
  const authData = useAuthData()
  const { addLoading, deleteLoading, addErrors } = useTrackDataAPIStore()
  const requestInterceptor = (config: InternalAxiosRequestConfig) => {
    if (config.trackId) {
      addLoading(config.trackId)
    }
    return config
  }

  const errorInterceptor = (error: AxiosRequestConfig) => {
    return Promise.reject(error)
  }

  const responseInterceptor = (response: AxiosResponse) => {
    if (response.config.trackId) {
      deleteLoading(response.config.trackId)
    }
    return response
  }

  http.interceptors.request.use(requestInterceptor, errorInterceptor)

  let isRefreshing = false
  let refreshSubscribers: Array<() => void> = []
  http.interceptors.response.use(responseInterceptor, (error) => {
    deleteLoading(error.config.trackId)

    if (error.config.hasAlreadyBeenRetry) {
      return Promise.reject(error)
    }

    if (error.config.url === '/admin/oauth/login') {
      addErrors(error.config.trackId, error.response.data)
      return Promise.reject(error)
    }

    if (error.response.status === 401) {
      // Case when user is not an keycloak user with refresh or validate token
      // Case when it's the refresh-token or validate-token call in error
      if (['/oauth/refresh-token'].includes(error.config.url)) {
        authData.redirectUrl = window.location.href
        router.push({
          name: 'sign-in',
          query: { message: 'common.errors.tokenInvalid' }
        })
        // Case when t's the access token that need to be refresh
      } else {
        // If no token refreshing is currently in progress, call for refresh token
        if (!isRefreshing) {
          isRefreshing = true
          refreshToken()
            .then(() => {
              onRefreshedToken()
            })
            .finally(() => {
              isRefreshing = false
            })
        }
        // Add request in error in an array to be replay when token is refresh
        const retryOrigReq = new Promise((resolve) => {
          subscribeTokenRefresh(() => {
            error.config.hasAlreadyBeenRetry = true
            // replace the expired token and retry
            resolve(http(error.config))
          })
        })
        return retryOrigReq
      }
    }
    addErrors(error.config.trackId, error.response.data)
    return Promise.reject(error)
  })

  const subscribeTokenRefresh = (cb: () => void) => {
    refreshSubscribers.push(cb)
  }

  const onRefreshedToken = () => {
    refreshSubscribers.forEach((cb) => cb())
    refreshSubscribers = []
  }
}
