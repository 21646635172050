export default {
  billing: {
    partnerBilling: {
      menu: {
        billingPerMonth: 'Facturation par mois',
        billingTemporary: 'Facturation temporaire'
      },
      title: 'Facturation des partenaires',
      download: 'Télécharger le rapport de facturation',
      downloadReportDay: 'Télécharger le rapport de facturation du jour',
      table: {
        title: 'Liste des facturations',
        columns: {
          reportingHistory: 'Historique des reporting',
          dateCreated: 'Jour de génération'
        },
        download: 'Télécharger'
      },
      generate: 'Générer et envoyer les factures en attentes',
      reporting: {
        generate: 'Générer le rapport de facturation et préparer les factures'
      }
    },
    contractConsult: {
      title: 'Contrats de facturation de {businessName}',
      add: 'Ajouter un contrat'
    },
    contractEdit: {
      title: 'Éditer la facturation',
      cancel: 'Annuler',
      save: 'Enregistrer'
    },
    form: {
      contract: {
        fields: {
          commissionRate: 'Taux de commission',
          retrocommisionRate: 'Taux de retrocommission',
          membershipNumber: 'N° Adhérent Financo',
          signedDate: 'Date de signature',
          terminationDate: 'Date de rupture',
          reasonForTermination: 'Motif de rupture',
          commissionDpe: 'Forfait commission DPE',
          commissionAudit: 'Forfait commission Audit'
        }
      }
    },
    partnerContract: {
      reasonForTermination: {
        COMMERCIAL: 'Commercial',
        STRATEGIC_DIVERGENCES: 'Divergences stratégiques',
        CLAIM: 'Réclamation',
        RGE_QUALIFICATION_EXPIRED: 'Qualification RGE expirée'
      },
      errors: {
        create: 'Une erreur est survenue lors de la création du contrat',
        update: 'Une erreur est survenue lors de la modification du contrat'
      },
      toasts: {
        created: {
          title: 'Le contract a été créé !'
        },
        updated: {
          title: 'Le contract a été modifié !'
        },
        deleted: {
          title: 'Le contract a été supprimée !'
        }
      }
    }
  }
}
