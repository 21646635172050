import { checkTokenValidity, validateAccompinistInvitation } from '@/common/services/usersService'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
export const useAuthGuard = () => {
  /**
   * Vérifie si un token est présent dans les paramètres de requête de la route de destination.
   * Si le token est présent, appelle la fonction 'next' pour continuer la navigation.
   * Si le token n'est pas présent, redirige vers la route 'createAccount' avec un message d'erreur.
   *
   * @param to - La route de destination.
   * @param from - La route actuelle.
   * @param next - La fonction à appeler pour continuer la navigation.
   */
  const checkTokenGuard = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    if (to.query.uuid) {
      try {
        const validToken = await checkTokenValidity(to.query.uuid as string)

        if (validToken.valid) {
          next()
        } else {
          next({ name: 'sign-in', query: { message: 'common.errors.tokenInvalid' } })
        }
      } catch {
        next({ name: 'sign-in', query: { message: 'common.errors.tokenInvalid' } })
      }
    } else {
      next({ name: 'sign-in', query: { message: 'common.errors.tokenInvalid' } })
    }
  }

  const checkAccountExist = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const uuid = to.query.uuid as string
    try {
      const res = await validateAccompinistInvitation(uuid)
      if (res.exist) {
        next({ name: 'sign-in', query: { uuid } })
      } else {
        next({ name: 'createAccountLastStep', query: { uuid } })
      }
    } catch {
      next({ name: 'sign-in' })
    }
  }

  const checkUuidPresent = async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    const uuid = to.query.uuid as string
    if (uuid) {
      next({ name: 'projectInvitation', params: { uuid } })
    }
    next()
  }

  return {
    checkTokenGuard,
    checkAccountExist,
    checkUuidPresent
  }
}
