export default {
  project: {
    libellePerformanceEnergetique: 'Performance énergétique',
    libelleMaintienADomicile: 'Maintien à domicile',
    creationDate: 'Date de création du projet: {date}',
    affiliatedCompany: 'Entreprise affiliée',
    accompanist: {
      libelle: 'Accompagnateur',
      notAssociated: 'Pas d’accompagnateur associé.'
    },
    professional: {
      libelle: 'Professionnel'
    },
    diagnostician: {
      libelle: 'Diagnostiqueur'
    },
    owner: {
      libelle: 'Propriétaire'
    },
    housingType: {
      home: 'Maison individuelle',
      apartment: 'Appartement',
      building: 'Immeuble'
    },
    timeline: {
      libelle: 'Etape en cours',
      steps: {
        PROJECT: 'Je définis Mon Projet',
        QUOTES: 'Je demande et sélectionne Mes Devis',
        FINANCING_FILE: 'Je finalise Mes Dossiers de financement',
        WORKS: 'Je fais réaliser et réceptionne Mes Travaux',
        SITE_RECEPTION: 'Je demande et réceptionne Mes Aides'
      },
      placeholder: 'Selectionnez une étape'
    },
    status: {
      DRAFT: 'Brouillon',
      ON_GOING: 'En cours',
      DISCOVERY: 'Découverte',
      PROPOSED_ACCOMPANIST: 'Proposé',
      TO_BE_PROCESSED: 'À traiter',
      REFUSED: 'Refusé',
      ACCEPTED: 'Accepté',
      UNKNOW: 'Inconnu'
    },
    workType: {
      workCategory: {
        HEATING: 'Chauffage et eau chaude',
        THERMAL_ISOLATION: 'Isolation, menuiserie et ventilation',
        ACCOMMODATION_ADAPTATION: 'Adaptation du logement',
        ENERGY_PRODUCTION: "Production d'énergie",
        WALL_ISOLATION: 'Isolation des murs',
        FLOOR_ISOLATION: 'Isolation des planchers bas',
        VENTILATION: 'Ventilation',
        DOOR_ISOLATION: 'Isolation des parois vitrées et portes',
        ROOF_ISOLATION: 'Isolation de la toiture',
        ADDITIONAL_WORK: 'Travaux additionnels'
      },
      title: {
        creation: "Création d'un type de travaux",
        modification: "Modification d'un type de travaux"
      },
      categoryLabel: 'Catégorie de travaux',
      categoryPlaceholder: 'Catégorie de travaux',
      wordingLabel: 'Libellé technique',
      wordingPlaceholder: 'Libellé technique',
      publicWordingLabel: 'Libellé',
      publicWordingPlaceholder: 'Libellé',
      noWorkTypes: 'Aucuns types de travaux',
      errors: {
        workTypeCategory: 'La catégorie de travaux est obligatoire',
        wording: 'Le libellé technique est obligatoire',
        publicWording: 'Le libellé est obligatoire',
        createWorkType: {
          CONFLICT: 'Un type de travaux existe déjà avec ce libellé technique'
        },
        updateWorkType: {
          CONFLICT: 'Un type de travaux existe déjà avec ce libellé technique'
        }
      }
    },
    state: {
      title: 'Situations du projet',
      choiceLibelle: 'Situation choisie',
      recommendationLibelle: 'Situation recommandée',
      flow: {
        GESTURE_BY_GESTURE_MPR: "MaPrimeRénov' par Geste",
        ACCOMPANIED_MPR: "MaPrimeRénov' Parcours Accompagné",
        WITHOUT_MPR: "Sans MaPrimeRénov'",
        DONT_KNOW: 'Indéfini'
      },
      toConfirm: 'À confirmer',
      validated: 'Confirmée',
      placeholder: 'Selectionnez une situation'
    },
    dashboard: {
      projectInformations: {
        title: 'Projet de rénovation',
        labels: {
          energyClass: 'CLASSE ÉNERGÉTIQUE',
          taxProfile: 'PROFIL FISCAL',
          works: 'TRAVAUX',
          worksWithNumber: 'TRAVAUX ({number})'
        }
      },
      professionals: {
        title: 'Equipe Rénov',
        accompanists: {
          title: 'ACCOMPAGNATEUR RÉNOV',
          tag: {
            accompanistAgency: 'Accompagnateur Rénov’',
            diagnosticianAgency: 'Partenaire'
          }
        },
        diagnosticians: {
          title: 'DIAGNOSTIQUEUR'
        },
        renovationProfessionals: {
          title: 'PROFESSIONNELS DU BATIMENT'
        }
      }
    },
    scenario: {
      scenarios: 'scenarios',
      label: 'Scénario',
      creationDate: 'Date de création du scénario: {date}',
      estimateAmountWork: 'Montant estimé des travaux :',
      estimateAmountFunding: 'Montant estimé des aides :',
      estimateleftAmount: 'Reste à charge :'
    },
    delete: {
      action: "Supprimer l'affiliation",
      popin: {
        title: "Supprimer l'affiliation d'un projet",
        accept: 'Oui, supprimer',
        cancel: 'Non, annuler',
        questionConfirm:
          "Êtes-vous sûr de vouloir supprimer l'affiliation avec {company} définitivement ?",
        descriptionAction: 'Ce projet ne sera plus associé à cette entreprise.'
      }
    }
  },
  projects: {
    title: 'Liste des projets',
    download: 'Télécharger la liste des projets'
  },
  period: {
    title: 'Période des projets :',
    label: 'Sélectionner une période'
  }
}
