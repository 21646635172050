export default [
  {
    path: 'documents',
    children: [
      {
        path: '',
        name: 'documents',
        component: () => import('@/document/views/DocumentList.vue')
      }
    ]
  }
]
