export default [
  {
    path: 'facturations',
    children: [
      {
        path: '',
        name: 'partnerBilling',
        component: () => import('@/billing/views/PartnerBilling.vue')
      }
    ]
  }
]
