import { defineStore } from 'pinia'
import { login, impersonate } from '../services/adminOauthService'
import { magicLinkSignIn, revokeToken } from '../services/usersService'

export const useAuthData = defineStore('AuthData', {
  state: () => {
    return {
      redirectUrl: ''
    }
  },
  actions: {
    login: async function (email: string, password: string) {
      await login(email, password)
    },
    logout: async function () {
      await revokeToken()
    },
    magicLinkLogin: async function (codeValidation: string) {
      await magicLinkSignIn(codeValidation)
    },
    impersonate: async function (email: string) {
      await impersonate(email)
    }
  }
})
