const fromBase = (path: string) => `/auth/${path}`

export default [
  {
    path: fromBase(`sign-in`),
    name: 'sign-in',
    component: () => import('@/account/views/SignIn.vue')
  },
  {
    path: fromBase('magic-link-signin'),
    name: 'magic-link-signin',
    component: () => import('@/account/views/MagicLinkCallbackSignIn.vue')
  }
]
