import { useAuthGuard } from '@/common/composables/useAuthGuard'

const { checkTokenGuard } = useAuthGuard()

export const userRoutes = [
  {
    path: 'informations-personnelles',
    name: 'myPersonalInformations',
    component: () => import('@/user/views/MyPersonalInformations.vue'),
    redirect: {
      name: 'myPersonalInformationsSynthese'
    },
    children: [
      {
        path: 'synthese',
        name: 'myPersonalInformationsSynthese',
        component: () => import('@/user/views/MyPersonalInformationsSynthese.vue')
      },

      {
        path: 'modification-mot-de-passe',
        name: 'passwordReset',
        component: () => import('@/user/views/PasswordReset.vue'),
        beforeEnter: checkTokenGuard
      }
    ]
  }
]
