import { defineStore } from 'pinia'
import type { ErrorAPI } from '@/common/models/errorAPI'
import type { HttpError } from '@/common/models/httpError'
import { computed, ref } from 'vue'

export const useTrackDataAPIStore = defineStore('TrackDataAPI', () => {
  const errorsAPI = ref(new Map<string, ErrorAPI>())
  const loading = ref<string[]>([])

  const hasLoading = computed(() => loading.value.length > 0)

  const hasLoadingByTrackId = (trackId: string) =>
    loading.value.find((id) => id == trackId) !== undefined

  const hasLoadingByTrackIds = (...trackIds: string[]) =>
    trackIds.some((trackId) => loading.value.includes(trackId))

  const getErrorByTrackId = (trackId: string) => errorsAPI.value.get(trackId)

  const addErrors = (trackId: string, error: ErrorAPI) => {
    errorsAPI.value.set(trackId, error)
  }
  const addHttpErrors = (trackId: string, error: HttpError) =>
    errorsAPI.value.set(trackId, createErrorAPIFromResponse(trackId, error))

  const removeErrors = (trackId: string) => errorsAPI.value.delete(trackId)

  const addLoading = (trackId: string) => loading.value.push(trackId)

  const deleteLoading = (trackId: string) => {
    setTimeout(() => {
      const index = loading.value.indexOf(trackId)
      if (index > -1) {
        loading.value.splice(index, 1)
      }
    }, 20)
  }

  return {
    errorsAPI,
    loading,
    getErrorByTrackId,
    hasLoading,
    hasLoadingByTrackId,
    hasLoadingByTrackIds,
    addErrors,
    addHttpErrors,
    removeErrors,
    addLoading,
    deleteLoading
  }
})

const createErrorAPIFromResponse = (trackId: string, error: HttpError): ErrorAPI => {
  const defaultErrorAPI: ErrorAPI = {
    trackId: trackId,
    httpCode: 500,
    messageKey: '',
    message: 'Une erreur inattendue est survenue.'
  }
  if (error.response) {
    // Vérifier si error.response.data existe et est complet
    if (error.response.data) {
      return error.response.data as ErrorAPI
    } else {
      // Construire ErrorAPI à partir des informations disponibles
      return {
        ...defaultErrorAPI,
        httpCode: error.response.status,
        message: error.response.statusText || defaultErrorAPI.message
      }
    }
  }
  // Cas où error.response n'existe pas
  return defaultErrorAPI
}
