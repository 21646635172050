export default [
  {
    path: 'clients',
    children: [
      {
        path: '',
        name: 'clients',
        component: () => import('@/client/views/ClientList.vue')
      },
      {
        path: ':clientId',
        children: [
          {
            path: '',
            name: 'clientDetail',
            meta: {
              backWording: 'client.projects.backWording.detail',
              backName: 'clients'
            },
            component: () => import('@/client/views/ClientDetail.vue')
          },
          {
            path: 'projets/:projectId',
            name: 'clientProjectSummary',
            meta: {
              backWording: 'client.projectSummary.backWording.detail',
              backName: 'clientDetail'
            },
            component: () => import('@/project/views/ProjectSummary.vue')
          }
        ]
      }
    ]
  }
]
