import { http } from '@/common/api'

export const login = (email: string, password: string, trackId = 'oauth-login'): Promise<void> => {
  const body = `email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`
  return http
    .post('/admin/oauth/login', body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      trackId
    })
    .then((response) => response.data)
}

export const impersonate = (email: string, trackId = 'oauth-impersonate'): Promise<void> => {
  const body = `email=${encodeURIComponent(email)}`
  return http
    .post('/admin/oauth/impersonate', body, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      trackId
    })
    .then((response) => response.data)
}
