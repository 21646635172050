<script setup lang="ts">
import PanelMenu from 'primevue/panelmenu'
import { useAuthData } from '@/common/stores/authData'
import MenuItem from './MenuItem.vue'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    isMobile?: boolean
  }>(),
  {
    isMobile: false
  }
)

const { t } = useI18n()
const authData = useAuthData()
const router = useRouter()

const logout = () => {
  authData.logout()
  router.push({ name: 'sign-in' })
}

const itemsPartOne = ref([
  {
    id: 'dashboard',
    label: t('common.navigation.menu.myDashboard'),
    route: { name: 'dashboard' }
  },
  {
    id: 'clients',
    label: t('common.navigation.menu.clients'),
    route: { name: 'clients' }
  },
  {
    id: 'projects',
    label: t('common.navigation.menu.projects'),
    route: { name: 'projects' }
  },
  {
    id: 'documents',
    label: t('common.navigation.menu.documents'),
    route: { name: 'documents' }
  },
  {
    label: t('common.navigation.menu.renovationTeam'),
    items: [
      {
        label: t('common.navigation.menu.accompanists'),
        items: [
          {
            id: 'accompanistsCompanies',
            label: t('common.navigation.menu.companies'),
            route: { name: 'accompanistsCompanies' }
          },
          {
            id: 'accompanistsAgencies',
            label: t('common.navigation.menu.agencies'),
            route: { name: 'accompanistsAgencies' }
          },
          {
            id: 'accompanists',
            label: t('common.navigation.menu.naturalPersonsAccompanist'),
            route: { name: 'accompanists' }
          }
        ]
      },
      {
        label: t('common.navigation.menu.professionals'),
        items: [
          {
            id: 'professionalsCompanies',
            label: t('common.navigation.menu.companies'),
            route: { name: 'artisansCompanies' }
          },
          {
            id: 'professionalsAgencies',
            label: t('common.navigation.menu.agencies'),
            route: { name: 'professionalAgencies' }
          },
          {
            id: 'professionals',
            label: t('common.navigation.menu.naturalPersonsProfessional'),
            route: { name: 'artisans' }
          }
        ]
      },
      {
        label: t('common.navigation.menu.diagnosticians'),
        items: [
          {
            id: 'diagnosticiansCompanies',
            label: t('common.navigation.menu.companies'),
            route: { name: 'diagnosticiansCompanies' }
          },
          {
            id: 'diagnosticiansAgencies',
            label: t('common.navigation.menu.agencies'),
            route: { name: 'diagnosticiansAgencies' }
          }
        ]
      }
    ]
  },
  {
    id: 'partnerMap',
    label: t('common.navigation.menu.partnerMap'),
    route: { name: 'partnerMap' }
  },
  {
    id: 'billing',
    label: t('common.navigation.menu.billing'),
    route: { name: 'partnerBilling' }
  }
])

const itemsPartTwo = ref([
  {
    label: t('common.navigation.menu.technic'),
    items: [
      {
        id: 'recoveryPage',
        label: t('common.navigation.menu.recovery'),
        route: { name: 'recoveryPage' }
      },
      {
        id: 'featureFlipping',
        label: t('common.navigation.menu.featureFlipping'),
        route: { name: 'featureFlippingView' }
      }
    ]
  },
  {
    label: t('common.navigation.menu.statistics'),
    items: [
      {
        id: 'activityKpi',
        label: t('common.navigation.menu.activityKpi'),
        route: { name: 'activityKpiView' }
      },
      {
        id: 'activityKpiMarketing',
        label: t('common.navigation.menu.activityKpiMarketing'),
        route: { name: 'activityKpiMarketingView' }
      }
    ]
  }
])
</script>
<template>
  <nav data-testid="navigation-menu">
    <ul
      :class="[
        'list-none flex flex-column align-items-start p-0 m-0 gap-3',
        { 'menu-white': isMobile, menu: !isMobile }
      ]"
    >
      <PanelMenu
        :model="itemsPartOne"
        class="custom-panel-menu w-full"
        :pt="{
          headerContent: { class: 'panelmenu-header-content' },
          toggleableContent: { class: 'no-padding-list' }
        }"
      >
        <template #item="{ item }">
          <router-link
            v-if="item.route"
            :to="item.route"
            custom
            v-slot="{ href, navigate, isActive }"
          >
            <MenuItem
              :id="item.id"
              :libelle="item.label as string"
              :active="isActive"
              :href="href"
              :navigate="navigate"
              @click="navigate"
            />
          </router-link>
          <a
            v-else
            class="flex align-items-center cursor-pointer px-2 py-2"
            :href="item.url"
            :target="item.target"
          >
            <span :class="item.icon" />
            <span class="ml-2 text-white lg:text-color-secondary font-semibold">{{
              item.label
            }}</span>
            <span
              v-if="item.items"
              class="ti ti-chevron-down text-white lg:text-primary ml-auto mr-2 lg:mr-0"
            />
          </a>
        </template>
      </PanelMenu>

      <li class="separation" />

      <PanelMenu
        :model="itemsPartTwo"
        class="custom-panel-menu w-full"
        :pt="{
          headerContent: { class: 'panelmenu-header-content' },
          toggleableContent: { class: 'no-padding-list' }
        }"
      >
        <template #item="{ item }">
          <router-link
            v-if="item.route"
            :to="item.route"
            custom
            v-slot="{ href, navigate, isActive }"
          >
            <MenuItem
              :id="item.id"
              :libelle="item.label as string"
              :active="isActive"
              :href="href"
              :navigate="navigate"
              @click="navigate"
            />
          </router-link>
          <a
            v-else
            class="flex align-items-center cursor-pointer px-2 py-2"
            :href="item.url"
            :target="item.target"
          >
            <span :class="item.icon" />
            <span class="ml-2 text-white lg:text-color-secondary font-semibold">{{
              item.label
            }}</span>
            <span
              v-if="item.items"
              class="ti ti-chevron-down text-white lg:text-primary ml-auto mr-2 lg:mr-0"
            />
          </a>
        </template>
      </PanelMenu>

      <li class="separation" />

      <router-link
        :to="{ name: 'myPersonalInformations' }"
        custom
        v-slot="{ href, navigate, isActive }"
      >
        <MenuItem
          id="personalData"
          :libelle="$t('common.navigation.menu.myPersonalData')"
          :active="isActive"
          :href="href"
          :navigate="navigate"
        />
      </router-link>

      <li class="separation" />

      <router-link :to="{ name: 'sign-in' }" custom v-slot="{ href, isActive }">
        <MenuItem
          id="logout"
          :libelle="$t('common.navigation.menu.logout')"
          :active="isActive"
          :href="href"
          :navigate="logout"
        />
      </router-link>
    </ul>
  </nav>
</template>

<style lang="scss">
.separation {
  width: 100%;
  border-top: 1px solid;
  padding: 0;
}

.menu .separation {
  border-image-slice: 1;
  border-image-source: linear-gradient(180deg, #0a9ba5, #72b74a);
}

.menu-white .separation {
  border-color: white;
}

.custom-panel-menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .p-panelmenu-panel {
    margin: 0;
  }
  .p-panelmenu-header-content {
    background: none;
    border: none;
    margin-bottom: 0;
    font-weight: bold;
  }
  .p-panelmenu-content {
    background: none;
    border: 1px solid #dee2e6;
    border-radius: var(--border-radius-size);
    padding: 0;

    .p-menuitem {
      background: none;
      padding: 0.5rem;
      > .p-menuitem-content {
        border-radius: var(--border-radius-size);
      }
    }
  }
}
</style>
