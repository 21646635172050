import axios from 'axios'

declare module 'axios' {
  export interface AxiosRequestConfig {
    trackId?: string
  }
}

const http = axios.create({
  baseURL: '/api'
})

export { http }
