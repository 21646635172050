<script lang="ts" setup>
import TheClientHeader from '../components/layout/TheClientHeader.vue'
import NavigationMenu from '../components/navigation/NavigationMenu.vue'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import ProgressSpinner from 'primevue/progressspinner'

const route = useRoute()
const withoutMenu = computed(() => route.meta.landingPage)
</script>
<template>
  <router-view name="header">
    <TheClientHeader />
  </router-view>

  <div class="layout">
    <div role="navigation" class="layout-navigation-left" v-if="!withoutMenu">
      <NavigationMenu />
    </div>

    <div class="flex w-full flex-column">
      <div class="flex flex-grow-1 justify-content-center">
        <main class="main-private">
          <router-view v-slot="{ Component }">
            <Suspense timeout="0">
              <template #default>
                <Component :is="Component" />
              </template>
              <!-- loading state via #fallback slot -->
              <template #fallback>
                <div class="flex align-items-center justify-content-center w-full">
                  <ProgressSpinner />
                </div>
              </template>
            </Suspense>
          </router-view>
        </main>
      </div>
    </div>
  </div>
</template>
